import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import AddAITranslation from "./components/AITools/AITranslation/AddAIToolsTranslation";
import PageLayout from "./components/PageLayout";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  return (
    <div className="app-container">
      <div className="content">
        <Routes>
          <Route path="/" element={<AddAITranslation />} />
          <Route element={<PageLayout />}></Route>
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
