export const getUserRole = (): string | null => {
  const userData = localStorage.getItem("user");
  if (userData) {
    try {
      const user = JSON.parse(userData);
      return user.roles && user.roles.length > 0
        ? user.roles[0].role_name
        : null;
    } catch (error) {
      console.error("Error parsing user data:", error);
      return null;
    }
  }
  return null;
};
