import React, { useEffect, useState } from "react";
import {
  Layout,
  Input,
  Button,
  Select,
  Tabs,
  Typography,
  Upload,
  message,
  Spin,
} from "antd";
import TopBar from "../../../Topbar/Header";
import HeaderAITool from "../../../../assets/img/header.png";
import secondPicture from "../../../../assets/img/layer_.png";
import "./index.css";
// import {} from "antd";
import axios, { AxiosRequestConfig } from "axios";
import TranslationModal from ".././../../Modal/TranslationModal";
import ModalImage from "../../../../assets/img/Frame.png";
import { RcFile } from "antd/es/upload";
// import {} from "antd";
import {
  DownloadOutlined,
  CopyOutlined,
  ArrowLeftOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { get, post } from "../../../../utils/apiUtils";
import { getUserRole } from "../../../../hooks/getUserRoles";
import { useParams } from "react-router-dom";

const { Content } = Layout;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
const { Title } = Typography;
const { Dragger } = Upload;

type LanguageType = {
  id: string;
  name: string;
  code: string;
};

function AITranslation() {
  const userRole = getUserRole();
  const { id: projectId } = useParams();
  const [activeTab, setActiveTab] = useState("1"); // State to keep track of active tab
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [translationId, setTranslationId] = useState(null); // You'll need to set this to the correct id after the translation is done
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [jsonInput, setJsonInput] = useState("");
  const [supportedLanguages, setSupportedLanguages] = useState<LanguageType[]>(
    []
  );

  const [language, setLanguage] = useState("");
  const [jsonName, setJsonName] = useState("");
  const [textName, setTextName] = useState("");
  const [excelName, setExcelName] = useState("");
  const [textInput, setTextInput] = useState("");
  // Function to show the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle the ok button in the modal
  const handleOk = () => {
    setIsModalVisible(false);
    // Place the logic for what should happen when "Ok" is clicked
  };

  // Function to handle the cancel button in the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    // Fetch the supported languages from the endpoint when the component mounts
    const fetchSupportedLanguages = async () => {
      try {
        const response = await get("supported_languages/", {});
        setSupportedLanguages(response.data as LanguageType[]);
      } catch (error) {
        console.error(
          "There was an error fetching the supported langges:",
          error
        );
      }
    };

    fetchSupportedLanguages();
  }, []);

  const handleLanguageChange = (value: string) => {
    console.log("value", value);
    setLanguage(value); // Assuming you still need the code as a string for API calls
  };
  const token = localStorage.getItem("token");

  const translateJson = async () => {
    const formData = new URLSearchParams();

    // const projectId = useParams().id;

    formData.append("json_data", jsonInput);
    formData.append("to_lang", language);

    console.log("language;", language);
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading

    try {
      // const response = await post<any>("translate_json_endpoint", formData, {
      //   headers,
      // });
      const response = await post<any>(
        "public_translate_json_endpoint/",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // If you need an authorization token, uncomment and update the following line:
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("res i json:", response);
      // if (response.type === "success") {
      if (response.type === "success") {
        message.success("Translation successful");
        setTranslationId(response?.data?.translation_id);
        setJsonName(response?.data?.filename);
        setIsModalVisible(true);
        setIsLoading(false);
      } else {
        throw new Error(`HTTP error! status: ${response.type}`);
        setIsLoading(false);
      }
    } catch (error: any) {
      // console.error("Error response:", error.response);
      // console.error("Error headers:", error.response.headers);
      message.error(`Translation failed: ${error.message}`);
      setIsLoading(false);
    }
  };

  // Translation function for file upload
  const onFileChange = (info: any) => {
    const { file, fileList } = info;

    if (file.status !== "uploading") {
      console.log(file, fileList);
    }

    if (file.status === "done") {
      message.success(`${file.name} file selected successfully.`);
    } else if (file.status === "error") {
      message.error(`${file.name} file selection failed.`);
    }

    // This will create a new array with the files having the properties we need
    const newFileList = fileList.map((file: any) => ({
      uid: file.uid,
      name: file.name,
      status: file.status,
      // If you need the original File object, you can use 'originFileObj'
      // But ensure that 'originFileObj' is a property of the file object in Ant Design's current version
      originFileObj: file.originFileObj || file, // Fallback to the file itself if 'originFileObj' is undefined
    }));

    setFileList(newFileList);
  };

  const translateFile = async () => {
    if (fileList.length === 0 || !language) {
      message.error("Please select a file and language first.");
      return;
    }

    // Type assertion: we're telling TypeScript that fileList[0] is any type
    // and then asserting that originFileObj is a File.
    const fileToUpload = (fileList[0] as any).originFileObj as File;
    const formData = new FormData();
    formData.append("file", fileToUpload); // The actual file needs to be appended
    formData.append("to_lang", language);
    // if (projectId) {
    //   formData.append("project_id", projectId);
    // } else {
    //   throw new Error("Project ID is undefined");
    // }
    setIsLoading(true); // Start loading

    try {
      let headers: AxiosRequestConfig["headers"] = {
        "Content-Type": "application/x-www-form-urlencoded",
      };

      // const response = await post<any>("translate_excel_file", formData, {
      //   headers,
      // });
      const response = await axios.post(
        "https://aitools.kapinsights.com/public_translate_excel_file/",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // If you need an authorization token, uncomment and update the following line:
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // if (response.type === "success") {
      if (response.status == 200) {
        setTranslationId(response.data.translation_id);
        setExcelName(response?.data?.filename);
        message.success("Translation started.");
        setIsModalVisible(true);
        setIsLoading(false);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
        setIsLoading(false);
      }
    } catch (error) {
      message.error("An error occurred during translation.");
      console.error("Translation error:", error);
      setIsLoading(false);
    }
  };

  const translateText = async () => {
    const formData = new URLSearchParams();
    formData.append("text", textInput);
    formData.append("to_lang", language);
    // if (projectId) {
    //   formData.append("project_id", projectId);
    // } else {
    //   // Handle the case where projectId is undefined
    //   throw new Error("Project ID is undefined");
    // }

    try {
      // Construct form data
      // const formData = new URLSearchParams();
      // formData.append("text", textInput);
      // formData.append("to_lang", language);

      // Retrieve token from local storage
      const token = localStorage.getItem("token");
      setIsLoading(true); // Start loading

      console.log("token::", token);
      let headers: AxiosRequestConfig["headers"] = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        "https://aitools.kapinsights.com/public_translate_text_endpoint/",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // If you need an authorization token, uncomment and update the following line:
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200) {
        console.log("response:", response);
        message.success(response?.data?.message);
        setTranslationId(response?.data?.translation_id);
        setTextName(response?.data?.filename);
        setIsModalVisible(true);
        setIsLoading(false);
      } else {
        console.log("responseee::", response);
        throw new Error(`HTTP error! status: ${response.status}`);
        setIsLoading(false);
      }
      console.log("response", response);
    } catch (error) {
      console.error("There was an error translating the text:", error);
      message.error("Translation failed");
      setIsLoading(false);
    }
  };

  // Universal translate function to call the appropriate one based on the active tab
  const translate = () => {
    if (activeTab === "1") {
      translateJson();
    } else if (activeTab === "2") {
      translateFile();
    } else if (activeTab === "3") {
      translateText();
    }
  };

  const handleDownload = async () => {
    console.log("translation:", translationId);
    if (!translationId) return;

    let filename = "downloaded_file"; // A default filename, in case it's not set

    // Choose the filename based on the active tab
    if (activeTab === "1") {
      filename = jsonName;
    } else if (activeTab === "2") {
      filename = excelName;
    } else if (activeTab === "3") {
      filename = textName;
    }

    try {
      const response = await axios({
        url: `https://aitools.kapinsights.com/translations/download/${translationId}`,
        method: "GET",
        responseType: "blob", // important
      });

      const blob = new Blob([response.data], {
        type: response.headers["content-type"] || "application/octet-stream",
      });

      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = filename || "translated_file";
      document.body.appendChild(link);
      link.click();

      // Clean up after download
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);

      message.success("Download started!");
    } catch (error) {
      console.error("Download error:", error);
      message.error("Download failed");
    }
  };

  const handleCopy = async () => {
    if (!translationId) {
      message.error("No translation available to copy.");
      return;
    }

    setIsLoading(true); // Start loading``

    try {
      const response = await axios.get(
        `https://aitools.kapinsights.com/copy_translation/${translationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob", // We'll start with a blob and convert as needed
          // responseType: "json",
        }
      );

      // Convert the blob to text
      const text = await response.data.text();

      // Check the content type of the response
      if (response?.headers["content-type"].includes("application/json")) {
        // Parse JSON
        const json = JSON.parse(text);

        // Check if 'content' is an object
        if (json.content && typeof json.content === "object") {
          // Stringify the object
          await navigator.clipboard.writeText(JSON.stringify(json.content));
        } else {
          // If 'content' is just a string, copy it directly
          await navigator.clipboard.writeText(json.content);
        }
      } else {
        // If the content type is plain text, copy it directly
        await navigator.clipboard.writeText(text);
      }

      message.success("Translation copied to clipboard.");
    } catch (error) {
      console.error("Error copying the translation:", error);
      message.error("Failed to copy translation.");
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ padding: "2rem" }}>
      <Content
        style={{
          position: "relative",
          // marginTop: 24,
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            position: "relative",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1,
          }}
        >
          <img
            src={HeaderAITool}
            alt="AI Tool Header"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="translation-add-tool-content">
          <div>
            <Title className="title" level={5} style={{ margin: "unset" }}>
              Copy and paste to translate
            </Title>
            {/* <hr
                style={{ color: "#F4D9FF", background: "#F4D9FF !important" }}
              /> */}
            <p>
              This tool will help you to automatically translate code from one
              language to another. Just paste the code on the text box and
              select the language you want to translate your survey to.
            </p>
          </div>
          <div className="translate-part">
            <img
              src={secondPicture}
              alt="second picture"
              className="secondImage"
            />
            <div style={{ width: "100%" }}>
              <h2>Paste your text or upload a file</h2>
              <Tabs
                defaultActiveKey="1"
                onChange={setActiveTab}
                style={{ marginBottom: 24 }}
              >
                <TabPane tab="Json" key="1">
                  <TextArea
                    rows={9}
                    value={jsonInput}
                    onChange={(e) => setJsonInput(e.target.value)}
                  />
                </TabPane>

                <TabPane tab="File" key="2">
                  <Dragger
                    name="file"
                    multiple={false}
                    beforeUpload={(file) => {
                      const isXls =
                        file.type === "application/vnd.ms-excel" ||
                        file.type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                      if (!isXls) {
                        message.error(
                          `${file.name} is not a valid excel file.`
                        );
                        return Upload.LIST_IGNORE;
                      }
                      // You can directly set the fileList state here
                      setFileList([file]);
                      // Return false to prevent automatic upload
                      return false;
                    }}
                    onChange={onFileChange}
                    fileList={fileList.map((file) => ({
                      uid: file.uid,
                      name: file.name,
                      // status: file.status,
                      // originFileObj: file.originFileObj,
                    }))}
                    accept=".xls,.xlsx"
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined style={{ color: "#9B51E0" }} />
                    </p>
                    <p className="ant-upload-text">
                      Drag and drop files here, or click to select files
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit
                      from uploading company data or other band files.
                    </p>
                  </Dragger>
                </TabPane>

                <TabPane tab="Text" key="3">
                  <TextArea
                    rows={9}
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                  />
                </TabPane>
              </Tabs>

              <h2>Select the language you want to translate to</h2>
              <Select
                showSearch
                placeholder="Select a language"
                style={{ width: "100%", marginBottom: 24 }}
                value={language}
                onChange={handleLanguageChange}
                filterOption={(input, option: any) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {supportedLanguages?.map((lang) => (
                  <Option key={lang.id} value={lang.code}>
                    {lang.name} - {lang.code}
                  </Option>
                ))}
              </Select>

              <Button
                onClick={translate}
                className="translate-button"
                disabled={isLoading || userRole === "Guest"}
              >
                {isLoading ? (
                  <>
                    <Spin /> Wait
                  </>
                ) : (
                  "Translate"
                )}
              </Button>
            </div>
          </div>
        </div>
      </Content>
      <TranslationModal
        isVisible={isModalVisible}
        title=""
        content={
          <div
            className="modal-style"
            style={{
              display: "flex",
              flexDirection: "column",
              // gap: "2rem",
              alignItems: "center",
            }}
          >
            <img src={ModalImage} alt="image" />
            <h2
              style={{
                color: "#4A1C96",
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: "600",
                lineHeight: "24px",
                wordWrap: "break-word",
              }}
            >
              Your translation is ready!
            </h2>
            <p
              style={{
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
                textAlign: "center",
                color: "#808080",
              }}
            >
              Your content has been translated to{" "}
              {supportedLanguages.find((lang) => lang.code === language)?.name}{" "}
              successfully. You can copy or download it on the buttons below
            </p>
            {activeTab === "1" && (
              <p style={{ color: "#4A4A4A", fontWeight: "bold" }}>
                Please open JSON files in a text editor like Notepad.
              </p>
            )}
            {activeTab === "3" && (
              <p style={{ color: "#4A4A4A", fontWeight: "bold" }}>
                Please open text files in a text editor like Notepad.
              </p>
            )}
            {activeTab === "2" && (
              <p style={{ color: "#4A4A4A", fontWeight: "bold" }}>
                Please open Excel files in Microsoft Excel.
              </p>
            )}
            <div
              className="buttons-style"
              style={{
                display: "flex",
                gap: "2rem",
                marginBottom: "1rem",
              }}
            >
              <Button
                className="download-button"
                style={{
                  width: "152px",
                  gap: "2rem",
                  backgroundColor: "#A072EB",
                  border: "unset",
                  color: "white",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  lineHeight: "19px",
                }}
                onClick={handleDownload}
              >
                <DownloadOutlined /> Download
              </Button>
              {activeTab !== "2" && (
                <Button
                  className="copy-button"
                  style={{
                    width: "152px",
                    color: "white",
                    gap: "2rem",
                    backgroundColor: "#4A1C96",
                    border: "unset",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    lineHeight: "19px",
                  }}
                  onClick={handleCopy}
                >
                  <CopyOutlined /> Copy
                </Button>
              )}
            </div>
            <a
              style={{
                color: "#A072EB",
                fontSize: "12px",
                fontFamily: "Poppins",
                fontWeight: "500",
                lineHeight: "24px",
                wordWrap: "break-word",
              }}
              onClick={handleCancel}
            >
              <ArrowLeftOutlined style={{ marginRight: "8px" }} /> Go back to
              your translations
            </a>
          </div>
        }
        onOk={handleOk}
        onCancel={handleCancel}
      />
    </div>
  );
}

export default AITranslation;
