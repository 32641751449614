import React from "react";
import { Modal } from "antd";
import "./index.css";

type TranslationModalProps = {
  isVisible: boolean;
  title: string;
  content: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
};

const TranslationModal: React.FC<TranslationModalProps> = ({
  isVisible,
  title,
  content,
  onOk,
  onCancel,
}) => (
  <Modal
    title={title}
    visible={isVisible}
    onOk={onOk}
    onCancel={onCancel}
    className="translation-modal"
  >
    {content}
  </Modal>
);

export default TranslationModal;
