import React from "react";
import { Layout, Avatar, Menu, Dropdown, Button } from "antd";
import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

interface IUser {
  firstName: string | null;
  role: string;
}

interface TopBarProps {
  setOpenSidebar: (open: boolean) => void;
}

const TopBar: React.FC<TopBarProps> = ({ setOpenSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const onMenuClick = (event: any) => {
    if (event.key === "3") {
      const isRememberMe = localStorage.getItem("rememberMe");

      localStorage.removeItem("token");
      localStorage.removeItem("organizationId");
      localStorage.removeItem("user");
      localStorage.removeItem("isAuthenticates");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("currentProjectId");
      localStorage.removeItem("currentWorkspaceId");

      if (!isRememberMe) {
        localStorage.removeItem("rememberMe");
      }

      navigate("/");
    }
    if (event.key === "0") {
      navigate("/profile-information");
    }
  };

  const userMenu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="0">Profile</Menu.Item>
      {/* <Menu.Item key="1">Settings</Menu.Item> */}
      <Menu.Divider />
      <Menu.Item key="3">Logout</Menu.Item>
    </Menu>
  );

  let getUserString = localStorage.getItem("user");
  let username = "";
  let role = "role";

  if (getUserString) {
    const userObject = JSON.parse(getUserString);
    username = userObject.username;

    if (userObject.roles && userObject.roles.length > 0) {
      role = userObject.roles[0].role_name;
    }
  }
  const user: IUser = {
    firstName: username,
    role: role,
  };

  const userInitial =
    user?.firstName !== null ? user?.firstName?.charAt(0) : "";

  const isProjectDetailsPage =
    location.pathname.includes("/projects/") &&
    location.pathname.includes("/details");
  const isProjectsListPage = location.pathname.startsWith("/projects");

  const isSurveyManagementPage =
    location.pathname.startsWith("/ai-survey-generator") ||
    location.pathname.startsWith("/survey-builder") ||
    location.pathname.startsWith("/upload-survey") ||
    location.pathname.startsWith("/ai-translation");

  const isNewTranslationPage = location.pathname.startsWith(
    "/add-ai-translation"
  );

  // const handleBackButtonClick = () => {
  //   if (isProjectDetailsPage) {
  //     navigate(-1); // Go back to the previous page (projects list)
  //   } else if (isSurveyManagementPage) {
  //     navigate("/workspaces"); // Go back to the dashboard
  //   } else {
  //     navigate("/workspaces");
  //   }
  // };
  let projectId = localStorage.getItem("currentProjectId");

  const handleBackButtonClick = () => {
    if (isNewTranslationPage) {
      navigate(`/ai-translation/${projectId}`);
    } else if (isSurveyManagementPage) {
      if (projectId) {
        navigate(`/projects/${projectId}/details`);
      } else {
        navigate("/workspaces");
      }
    } else if (isProjectDetailsPage) {
      const workspaceId = localStorage.getItem("currentWorkspaceId");
      if (workspaceId) {
        navigate(`/projects/${workspaceId}`);
      } else {
        navigate("/workspaces");
      }
    } else {
      navigate("/workspaces");
    }
  };

  // const handleBackButtonClick = () => {
  //   if (isNewTranslationPage) {
  //     navigate("/ai-translation");
  //   } else if (isSurveyManagementPage) {
  //     const projectId = localStorage.getItem("currentProjectId");
  //     if (projectId) {
  //       navigate(`/projects/${projectId}/details`);
  //     } else {
  //       navigate("/workspaces");
  //     }
  //   } else if (isProjectDetailsPage) {
  //     navigate(-1); // Go back to the previous page (projects list)
  //   } else {
  //     navigate("/workspaces");
  //   }
  // };

  const backButtonText = isNewTranslationPage
    ? "Go back to translation list"
    : isSurveyManagementPage
    ? "Go back to dashboard"
    : isProjectDetailsPage
    ? "Go back to projects"
    : "Go back to workspaces";

  const headerClassName = `${
    isProjectDetailsPage || !location.pathname.startsWith("/projects")
      ? "header"
      : "removeMargin"
  }`;
  return (
    <Header className={headerClassName}>
      <div className="go-back-div">
        <div className="hamburger-item" onClick={() => setOpenSidebar(true)}>
          <MenuOutlined />
        </div>
        {(isProjectDetailsPage ||
          isProjectsListPage ||
          isSurveyManagementPage ||
          isNewTranslationPage) && (
          <Button
            // type="primary"
            className="go-backButton"
            onClick={handleBackButtonClick}
            style={{ marginRight: "20px" }}
          >
            <ArrowLeftOutlined />
            {backButtonText}
          </Button>
        )}
      </div>

      <div className="header-content">
        <div className="user-info">
          <div className="user-info-content">
            <span className="user-name">{user.firstName}</span>
            <span>{user.role}</span>
          </div>

          <Dropdown overlay={userMenu} trigger={["click"]}>
            <Avatar
              style={{
                backgroundColor: "#152c99",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              size="large"
            >
              {userInitial}
            </Avatar>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default TopBar;
