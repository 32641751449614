import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import TopBar from "./Topbar/Header";
import { Outlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./PageLayout.css";

const { Content, Footer } = Layout;

const PageLayout: React.FC = () => {
  const location = useLocation();
  const [openSidebar, setOpenSidebar] = useState(false);
  const isLoggedIn = !!localStorage.getItem("isAuthenticates");

  useEffect(() => {
    setOpenSidebar(false);
  }, [location]);

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  const isProjectDetailsPage =
    location.pathname.includes("/projects/") &&
    location.pathname.includes("/details");

  const layoutClassName = `${
    isProjectDetailsPage || !location.pathname.startsWith("/projects")
      ? "layout"
      : "removeMarginInLayout"
  }`;
  return (
    <>
      <TopBar setOpenSidebar={setOpenSidebar} />
      <Layout className={layoutClassName}>
        {(isProjectDetailsPage ||
          !location.pathname.startsWith("/projects")) && (
          <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        )}
        <Content
          className={`site-layout-background ${
            isProjectDetailsPage || !location.pathname.startsWith("/projects")
              ? openSidebar
                ? ""
                : "full-width-content"
              : ""
          }`}
        >
          <Outlet />
        </Content>

        {/* Footer Component */}
        <Footer
          style={{
            textAlign: "center",
            padding: "10px 30px",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <div className="footer-content">
            <div>
              <span>
                2024 ©{" "}
                <strong>
                  <a
                    href="https://www.kapinsights.com"
                    style={{ color: "black" }}
                  >
                    KAPInsights
                  </a>
                </strong>
              </span>
              <a href="mailto:support@kapinsights.com" className="footer-link">
                Email support
              </a>{" "}
            </div>
            <div>
              <a
                href="https://www.kapinsights.com/terms-of-service"
                className="footer-link"
              >
                Terms of Service
              </a>
              <a
                href="https://www.kapinsights.com/privacy-policy"
                className="footer-link"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.kapinsights.com/cookie-policy"
                className="footer-link"
              >
                Cookie Policy
              </a>
            </div>
          </div>
        </Footer>
      </Layout>
    </>
  );
};

export default PageLayout;
